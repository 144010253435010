import { createAction, props } from '@ngrx/store';
import { ICstWallet } from '../../common/models/cst-wallet.model';

export const getCstWalletDetails = createAction(
  '[CUSTOMER PAGE] get wallet details',
  props<{ svCustomerId: string; walletId: string; hardRefresh: boolean }>()
);

export const getCstWalletDetailsSuccess = createAction(
  '[CUSTOMER API] get wallet details success',
  props<{ data: ICstWallet }>()
);

export const getCstWalletDetailsFailed = createAction(
  '[CUSTOMER API] get wallet details failed',
  props<{ error: any }>()
);
export const clearCstWalletDetails = createAction(
  '[CUSTOMER PAGE] clear wallet details'
);
