import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CommonApiService } from 'src/app/services/common-api.service';
import {
  getCstWalletDetails,
  getCstWalletDetailsFailed,
  getCstWalletDetailsSuccess,
} from './customer.actions';
import { catchError, EMPTY, first, map, of, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectCstWalletData } from './customer.selectors';
import { API } from 'src/app/common/models/api.enum';
import { ICstWallet } from '../../common/models/cst-wallet.model';

export const loadCstWalletDetails = createEffect(
  (
    actions$ = inject(Actions),
    apiService = inject(CommonApiService),
    store = inject(Store)
  ) => {
    return actions$.pipe(
      ofType(getCstWalletDetails),
      switchMap((action) =>
        store.select(selectCstWalletData).pipe(
          first(),
          switchMap((data) =>
            data && !action.hardRefresh && data.svWalletId === action.walletId
              ? EMPTY
              : apiService
                  .get<ICstWallet>(
                    API.WALLETS.replace(
                      'SV_CUSTOMER_ID',
                      action.svCustomerId
                    ).replace('WALLET_ID', action.walletId)
                  )
                  .pipe(
                    map((data) => getCstWalletDetailsSuccess({ data })),
                    catchError((error) =>
                      of(getCstWalletDetailsFailed({ error }))
                    )
                  )
          )
        )
      )
    );
  },
  { functional: true }
);
