import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAppState } from 'src/app/common/models/app.model';

export const superAdminFeatureKey = 'superAdmin';
export const selectSuperAdminState =
  createFeatureSelector<IAppState<any>>(superAdminFeatureKey);
export const selecSuperAdminData = createSelector(
  selectSuperAdminState,
  (state) => state.data
);
export const selecSuperAdminIsLoading = createSelector(
  selectSuperAdminState,
  (state) => state.isLoading
);
export const selecSuperAdminError = createSelector(
  selectSuperAdminState,
  (state) => state.error
);
