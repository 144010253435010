import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AlertPayload } from '../app-data/alert.data';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  showSuccessAlert: BehaviorSubject<AlertPayload | null>;
  showWarningAlert: BehaviorSubject<AlertPayload | null>;
  showDangerAlert: BehaviorSubject<AlertPayload | null>;

  constructor() {
    this.showSuccessAlert = new BehaviorSubject<AlertPayload | null>(null);
    this.showWarningAlert = new BehaviorSubject<AlertPayload | null>(null);
    this.showDangerAlert = new BehaviorSubject<AlertPayload | null>(null);
  }

  alertSuccess(alertTitle: string, alertSubTitle: string) {
    const payload = {
      title: alertTitle,
      subTitle: alertSubTitle,
      alertType: 'success',
    };
    this.showSuccessAlert.next(payload);
  }

  alertWarning(alertTitle: string, alertSubTitle: string) {
    const payload = {
      title: alertTitle,
      subTitle: alertSubTitle,
      alertType: 'warning',
    };
    this.showWarningAlert.next(payload);
  }

  alertDanger(alertTitle: string, alertSubTitle: string) {
    const payload = {
      title: alertTitle,
      subTitle: alertSubTitle,
      alertType: 'danger',
    };
    this.showDangerAlert.next(payload);
  }
}
