import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CommonApiService } from 'src/app/services/common-api.service';
import {
  getSuperAdminDetails,
  getSuperAdminDetailsFailed,
  getSuperAdminDetailsSuccess,
} from './super-admin.actions';
import { catchError, map, of, switchMap } from 'rxjs';
import { API } from 'src/app/common/models/api.enum';

export const loadSuperAdminDetails = createEffect(
  (actions$ = inject(Actions), apiService = inject(CommonApiService)) =>
    actions$.pipe(
      ofType(getSuperAdminDetails),
      switchMap(() =>
        apiService.get(API.USERS).pipe(
          map((data) => getSuperAdminDetailsSuccess({ data })),
          catchError((error) => of(getSuperAdminDetailsFailed({ error })))
        )
      )
    ),
  { functional: true }
);
