export enum API {
  USERS = '',
  LOGIN = 'iam/realms/account/login',
  LOGOUT = 'iam/realms/account/logout',
  VALIDATE = 'iam/realms/account/token/validate',
  CST_SERVICE_API = 'smartvault/service-accounts/SV_CUSTMOR_ID/WALLET_NAME/',
  CST_CC_SERVICE_API = 'smartvault/service-accounts/SV_CUSTMOR_ID',
  CSTACCOUNTLICENCE = 'smartvault/licence/',
  CST_CC_TEAM_MANAGEMENT_LISTING = 'smartvault/users/',
  CST_CC_ROLE = 'smartvault/roles/',
  SERVICE_NODE = 'smartvault/servicenode/roles/',
  CST_CC_ORG_INFORMATION = 'smartvault/customers/',
  CST_WALLETS_API = 'smartvault/api/v1/wallets/',
  WALLETS = 'smartvault/api/v1/wallets/SV_CUSTOMER_ID/WALLET_ID',
  ACTIVE_SERVICES_API = 'smartvault/service-accounts/getActiveServices',
  CST_ACCOUNT_LICENCE_BSP_UPDATE_CREDS = 'api/v1/SV_CUSTOMER_ID/SV_CUSTOMER_NAME/credentials-update',
  CST_RATE_CARD_LIST_API = 'smartvault/admin/SV_CUSTOMER_ID/WALLET_ID/rate-cards/audit',
}
