import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAuthState } from './auth.model';

export const authFeatureKey = 'auth';
export const selectAuthState =
  createFeatureSelector<IAuthState>(authFeatureKey);
export const selectUserData = createSelector(
  selectAuthState,
  (state) => state.data
);

export const selectUserisLoading = createSelector(
  selectAuthState,
  (state) => state.isLoading
);

export const selectUserError = createSelector(
  selectAuthState,
  (state) => state.error
);

export const selectValidateSessionData = createSelector(
  selectAuthState,
  (state) => state.validateSessionRes
);

export const selectLogoutSessionData = createSelector(
  selectAuthState,
  (state) => state.logoutRes
);
