import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { StorageService } from 'src/app/services/storage.service';

export const authGuard: CanMatchFn = (route, segments) => {
  const storageService = inject(StorageService);
  const router = inject(Router);
  const alertService = inject(AlertService);

  if (!storageService.getSessionToken()) {
    router.navigate(['/login']);
    alertService.alertDanger('Access Denied!', 'Session data not found!');
    return false;
  }
  return true;
};
