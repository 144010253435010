import { Injectable } from '@angular/core';
import { CryptoService } from './crypto.service';
import { ISessionData } from '../common/auth-feature/store/auth.model';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private cryptoService: CryptoService) {}
  getSessionToken(): string | null {
    const sessionToken = localStorage.getItem('sessionToken');
    return sessionToken;
  }

  setSessionToken(token: string) {
    localStorage.setItem('sessionToken', token);
  }

  getSessionData(): ISessionData | null {
    const sessionData = localStorage.getItem('sessionData');
    if (!sessionData) return null;
    try {
      const decryptedData = this.cryptoService.decryptData(sessionData);
      return JSON.parse(decryptedData);
    } catch (error) {
      console.warn(error);
      return null;
    }
  }

  setSessionData(data: any) {
    localStorage.setItem(
      'sessionData',
      this.cryptoService.encryptData(JSON.stringify(data))
    );
  }

  clearStorage() {
    localStorage.clear();
  }

  getModulePermissions(): string[] | null | undefined {
    return this.getSessionData()?.profile?.acl?.activeRbac?.modulePermissions;
  }
}
