import { createAction, props } from '@ngrx/store';

export const getSuperAdminDetails = createAction(
  '[SUPER-ADMIN PAGE] get super admin details'
);

export const getSuperAdminDetailsSuccess = createAction(
  '[SUPER-ADMIN API] get super admin details success',
  props<{ data: any }>()
);

export const getSuperAdminDetailsFailed = createAction(
  '[SUPER-ADMIN API] get super admin details failed',
  props<{ error: any }>()
);
