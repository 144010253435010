import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommonApiService {
  private baseURL: string = environment.backendurl;

  constructor(private http: HttpClient) {}

  private createHeaders(customHeaders?: any): HttpHeaders {
    let headers = new HttpHeaders();
    if (customHeaders) {
      Object.keys(customHeaders).forEach((key) => {
        headers = headers.append(key, customHeaders[key]);
      });
    }
    return headers;
  }

  private createParams(queryParams?: any): HttpParams {
    let params = new HttpParams();
    if (queryParams) {
      Object.keys(queryParams).forEach((key) => {
        const value = queryParams[key];
        if (value !== undefined && value !== null && value !== '') {
          params = params.append(key, value);
        }
      });
    }
    return params;
  }

  private handleRequest<T>(
    method: string,
    endpoint: string,
    body: any = null,
    queryParams?: any,
    customHeaders?: any
  ): Observable<T> {
    const url = `${this.baseURL}/${endpoint}`;
    const headers = this.createHeaders(customHeaders);
    const params = this.createParams(queryParams);

    switch (method) {
      case 'GET':
        return this.http.get<T>(url, { headers, params });
      case 'POST':
        return this.http.post<T>(url, body, { headers, params });
      case 'PUT':
        return this.http.put<T>(url, body, { headers, params });
      case 'PATCH':
        return this.http.patch<T>(url, body, { headers, params });
      case 'DELETE':
        return this.http.delete<T>(url, { headers, params, body });
      default:
        throw new Error(`Unsupported request method: ${method}`);
    }
  }

  get<T>(
    endpoint: string,
    queryParams?: any,
    customHeaders?: any
  ): Observable<T> {
    return this.handleRequest<T>(
      'GET',
      endpoint,
      null,
      queryParams,
      customHeaders
    );
  }

  post<T>(
    endpoint: string,
    body: any,
    queryParams?: any,
    customHeaders?: any
  ): Observable<T> {
    return this.handleRequest<T>(
      'POST',
      endpoint,
      body,
      queryParams,
      customHeaders
    );
  }

  put<T>(
    endpoint: string,
    body: any,
    queryParams?: any,
    customHeaders?: any
  ): Observable<T> {
    return this.handleRequest<T>(
      'PUT',
      endpoint,
      body,
      queryParams,
      customHeaders
    );
  }

  patch<T>(
    endpoint: string,
    body: any,
    queryParams?: any,
    customHeaders?: any
  ): Observable<T> {
    return this.handleRequest<T>(
      'PATCH',
      endpoint,
      body,
      queryParams,
      customHeaders
    );
  }

  delete<T>(
    endpoint: string,
    body: any = null,
    queryParams?: any,
    customHeaders?: any
  ): Observable<T> {
    return this.handleRequest<T>(
      'DELETE',
      endpoint,
      body,
      queryParams,
      customHeaders
    );
  }
}
