import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';

export const accessControlGuard: CanMatchFn = (route, segments) => {
  const routeName: string = route.data?.['routeName'];
  const aclData: string[] | null =
    inject(StorageService).getSessionData()?.profile?.acl?.activeRbac
      ?.modulePermissions || null;
  if (aclData?.length && aclData.includes(routeName)) return true;
  return false;
};
