import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAppState } from '../../models/app.model';
import { ICstWalletListApiRes } from '../../models/cst-wallet.model';

export const cstWalletListKey = 'cstWalletList';
export const selectCstWalletListState =
  createFeatureSelector<IAppState<ICstWalletListApiRes | null>>(
    cstWalletListKey
  );
export const selectCstWalletListData = createSelector(
  selectCstWalletListState,
  (state) => state.data
);
export const selectCstWalletListIsLoading = createSelector(
  selectCstWalletListState,
  (state) => state.isLoading
);
export const selectCstWalletListError = createSelector(
  selectCstWalletListState,
  (state) => state.error
);
