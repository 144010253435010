import { Injectable } from '@angular/core';
import { AES, enc } from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class CryptoService {
  constructor() {}
  key = 'SmartVault @ Smartping AI Udyog Vihar 254';

  encryptData(data: string): string {
    return AES.encrypt(data, this.key).toString();
  }

  decryptData(passwordToDecrypt: string): string {
    return AES.decrypt(passwordToDecrypt, this.key).toString(enc.Utf8);
  }
}
