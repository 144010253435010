import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const sessionToken = inject(StorageService).getSessionToken();
  let modifiedReq;
  if (!!sessionToken)
    modifiedReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${sessionToken}`),
    });

  return next(!!sessionToken ? modifiedReq : req);
};
