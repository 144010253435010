import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CommonApiService } from 'src/app/services/common-api.service';
import {
  getCstWalletList,
  getCstWalletListFailed,
  getCstWalletListSuccess,
} from './cst-wallet-list.actions';
import { catchError, forkJoin, map, of, switchMap } from 'rxjs';
import {
  ICstActiveServicesItem,
  ICstWalletListApiRes,
} from '../../models/cst-wallet.model';
import { API } from '../../models/api.enum';

export const cstWalletListEffect = createEffect(
  (actions$ = inject(Actions), apiService = inject(CommonApiService)) =>
    actions$.pipe(
      ofType(getCstWalletList),
      switchMap((action) =>
        forkJoin([
          apiService.get<ICstWalletListApiRes>(API.CST_WALLETS_API, {
            svCustomerId: action.svCustomerId,
            limit: 100,
            offset: 0,
          }),
          apiService.get<ICstActiveServicesItem[]>(API.ACTIVE_SERVICES_API, {
            svCustomerId: action.svCustomerId,
          }),
        ]).pipe(
          map(([walletListApiRes, activeServicesApiRes]) => {
            let walletData: any = {};
            let mergedRes = walletListApiRes;
            activeServicesApiRes?.forEach(
              (item) => (walletData[item.walletname] = item.activeServices)
            );
            mergedRes.wallets.forEach(
              (item, index) =>
                (mergedRes.wallets[index].activeServices =
                  walletData?.[item.svWalletId] || [])
            );
            return getCstWalletListSuccess({ data: mergedRes });
          }),
          catchError((error) => of(getCstWalletListFailed({ error })))
        )
      )
    ),
  { functional: true }
);
