import { createAction, props } from '@ngrx/store';
import { ICstWalletListApiRes } from '../../models/cst-wallet.model';

export const getCstWalletList = createAction(
  '[CST WALLET SELECTION PAGE] get wallet list',
  props<{ svCustomerId: string }>()
);

export const getCstWalletListSuccess = createAction(
  '[CST WALLET SELECTION API] get wallet list success',
  props<{ data: ICstWalletListApiRes }>()
);

export const getCstWalletListFailed = createAction(
  '[CST WALLET SELECTION API] get wallet list failed',
  props<{ error: any }>()
);
