<div class="alert-wrapper">
  <div
    *ngFor="let dataPayload of alertList"
    class="alert"
    [ngClass]="{
      'alert-success': dataPayload?.alertType === 'success',
      'alert-error': dataPayload?.alertType === 'danger',
      'alert-warning': dataPayload?.alertType === 'warning'
    }"
  >
    <em
      class="alert-icon"
      [ngClass]="{
        'bi-check-circle': dataPayload?.alertType === 'success',
        'bi-x-circle': dataPayload?.alertType === 'danger',
        'bi-exclamation-circle': dataPayload?.alertType === 'warning'
      }"
    >
    </em>
    <div class="alert-details">
      <h5 class="alert-title">{{ dataPayload?.title }}</h5>
      <p class="alert-sub-title">{{ dataPayload?.subTitle | titlecase }}</p>
    </div>
    <em class="bi bi-x-lg alert-cancel" (click)="closeAlert(dataPayload)"></em>
  </div>
</div>
