import { createAction, props } from '@ngrx/store';
import { ILogoutSessionRes, IValidateSessionRes } from './auth.model';

export const loginUser = createAction(
  '[LOGIN PAGE] login user',
  props<{ username: string; password: string }>()
);

export const loginUserWithOTP = createAction(
  '[LOGIN PAGE] login user with OTP',
  props<{ username: string; otp: string }>()
);

export const loginUserSuccess = createAction(
  '[LOGIN API] login user success',
  props<{ data: any }>()
);

export const loginUserFailed = createAction(
  '[LOGIN API] login user failed',
  props<{ error: any }>()
);

export const logoutUser = createAction('[SIDEBAR NAV PAGE] logout user');

export const logoutUserSuccess = createAction(
  '[SIDEBAR NAV API] logout user success',
  props<{ logoutRes: ILogoutSessionRes }>()
);

export const logoutUserFailed = createAction(
  '[SIDEBAR NAV API] logout user failed',
  props<{ error: any }>()
);

export const validateUser = createAction('[APP PAGE] validate session token');

export const validateUserSuccess = createAction(
  '[APP PAGE API] validate api success',
  props<{ validateSessionRes: IValidateSessionRes }>()
);

export const validateUserFailed = createAction(
  '[APP PAGE API] validate api failed',
  props<{ error: any }>()
);

export const clearUserData = createAction(
  '[ERROR INTERCEPTOR] clear user data'
);
