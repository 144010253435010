import { createReducer, on } from '@ngrx/store';
import { IAppState } from 'src/app/common/models/app.model';
import {
  getSuperAdminDetails,
  getSuperAdminDetailsFailed,
  getSuperAdminDetailsSuccess,
} from './super-admin.actions';

const initialState: IAppState<any> = {
  data: null,
  isLoading: false,
  error: null,
};

export const superAdminReducer = createReducer(
  initialState,
  on(getSuperAdminDetails, (state) => ({
    ...state,
    data: null,
    isLoading: true,
    error: null,
  })),
  on(getSuperAdminDetailsSuccess, (state, { data }) => ({
    ...state,
    data,
    isLoading: false,
    error: null,
  })),
  on(getSuperAdminDetailsFailed, (state, { error }) => ({
    ...state,
    data: null,
    isLoading: false,
    error,
  }))
);
