import { createReducer, on } from '@ngrx/store';
import * as authactions from './auth.actions';
import { IAuthState } from './auth.model';

const initialState: IAuthState = {
  data: null,
  isLoading: false,
  error: null,
  validateSessionRes: null,
  logoutRes: null,
};

export const authReducer = createReducer(
  initialState,
  on(authactions.loginUser, (state) => ({
    ...state,
    isLoading: true,
    error: null,
    validateSessionRes: null,
    logoutRes: null,
  })),
  on(authactions.loginUserWithOTP, (state) => ({
    ...state,
    isLoading: true,
    error: null,
    validateSessionRes: null,
    logoutRes: null,
  })),
  on(authactions.loginUserSuccess, (state, { data }) => ({
    ...state,
    data,
    isLoading: false,
  })),
  on(authactions.loginUserFailed, (state, { error }) => ({
    ...state,
    data: null,
    isLoading: false,
    error,
    logoutRes: null,
  })),
  on(authactions.logoutUser, (state) => ({
    ...state,
    isLoading: true,
    error: null,
    logoutRes: null,
  })),
  on(authactions.logoutUserSuccess, (state, { logoutRes }) => ({
    ...state,
    data: null,
    isLoading: false,
    validateSessionRes: null,
    logoutRes,
  })),
  on(authactions.logoutUserFailed, (state, { error }) => ({
    ...state,
    data: null,
    isLoading: false,
    error,
  })),
  on(authactions.validateUser, (state) => ({
    ...state,
    isLoading: true,
    error: null,
    validateSessionRes: null,
  })),
  on(authactions.validateUserSuccess, (state, { validateSessionRes }) => ({
    ...state,
    isLoading: false,
    validateSessionRes,
  })),
  on(authactions.validateUserFailed, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
    validateSessionRes: null,
  })),
  on(authactions.clearUserData, (state) => ({
    ...state,
    data: null,
    error: null,
    validateSessionRes: null,
    logoutRes: null,
  }))
);
