import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertPayload } from 'src/app/app-data/alert.data';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-alerts',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './alerts.component.html',
  styleUrl: './alerts.component.scss'
})
export class AlertsComponent implements OnInit, OnDestroy {
  alertList: AlertPayload[] = [];
  alertSuccessSubscription: Subscription;
  alertWarningSubscription: Subscription;
  alertDangerSubscription: Subscription;

  constructor(private alertservice: AlertService) {}

  ngOnInit(): void {
    this.alertSuccessSubscription =
      this.alertservice.showSuccessAlert.subscribe((value: AlertPayload) => {
        if (value) {
          this.showSuccessAlert(value);
        }
      });
    this.alertWarningSubscription =
      this.alertservice.showWarningAlert.subscribe((value: AlertPayload) => {
        if (value) {
          this.showWarningAlert(value);
        }
      });
    this.alertDangerSubscription = this.alertservice.showDangerAlert.subscribe(
      (value: AlertPayload) => {
        if (value) {
          this.showErrorAlert(value);
        }
      }
    );
  }

  showSuccessAlert(value: AlertPayload) {
    this.alertList.push(value);
    setTimeout(() => {
      this.alertList = this.alertList.filter((t) => t !== value);
      this.alertservice.showSuccessAlert.next(null);
    }, 4500);
  }

  showWarningAlert(value: AlertPayload) {
    this.alertList.push(value);
    setTimeout(() => {
      this.alertList = this.alertList.filter((t) => t !== value);
      this.alertservice.showWarningAlert.next(null);
    }, 4500);
  }

  showErrorAlert(value: AlertPayload) {
    this.alertList.push(value);
    setTimeout(() => {
      this.alertList = this.alertList.filter((t) => t !== value);
      this.alertservice.showDangerAlert.next(null);
    }, 6000);
  }

  closeAlert(value: AlertPayload) {
    this.alertList = this.alertList.filter((t) => t !== value);
    this.alertservice.showSuccessAlert.next(null);
    this.alertservice.showWarningAlert.next(null);
    this.alertservice.showDangerAlert.next(null);
  }

  ngOnDestroy(): void {
    this.alertSuccessSubscription.unsubscribe();
    this.alertWarningSubscription.unsubscribe();
    this.alertDangerSubscription.unsubscribe();
  }
}
