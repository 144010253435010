import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  loginUser,
  loginUserFailed,
  loginUserSuccess,
  logoutUser,
  logoutUserFailed,
  logoutUserSuccess,
  validateUser,
  validateUserFailed,
  validateUserSuccess,
} from './auth.actions';
import { CommonApiService } from 'src/app/services/common-api.service';
import { catchError, map, mergeMap, of } from 'rxjs';
import { API } from '../../models/api.enum';
import { HttpErrorResponse } from '@angular/common/http';
import {
  IAuthData,
  ILogoutSessionRes,
  IValidateSessionRes,
} from './auth.model';
import { Router } from '@angular/router';

export const loginUserEffect = createEffect(
  (actions$ = inject(Actions), apiService = inject(CommonApiService)) => {
    return actions$.pipe(
      ofType(loginUser),
      mergeMap(({ username, password }) =>
        apiService.post<IAuthData>(API.LOGIN, { username, password }).pipe(
          map((data) => loginUserSuccess({ data })),
          catchError((errorRes: HttpErrorResponse) =>
            of(loginUserFailed({ error: errorRes?.error || errorRes }))
          )
        )
      )
    );
  },
  { functional: true }
);

export const logoutUserEffect = createEffect(
  (
    actions$ = inject(Actions),
    apiService = inject(CommonApiService),
    router = inject(Router)
  ) => {
    return actions$.pipe(
      ofType(logoutUser),
      mergeMap(() =>
        apiService.post<ILogoutSessionRes>(API.LOGOUT, undefined).pipe(
          map((logoutRes) => logoutUserSuccess({ logoutRes })),
          catchError((errorRes: HttpErrorResponse) =>
            of(logoutUserFailed({ error: errorRes?.error || errorRes }))
          )
        )
      )
    );
  },
  { functional: true }
);

export const validateUserEffect = createEffect(
  (actions$ = inject(Actions), apiService = inject(CommonApiService)) => {
    return actions$.pipe(
      ofType(validateUser),
      mergeMap(() =>
        apiService.post<IValidateSessionRes>(API.VALIDATE, undefined).pipe(
          map((validateSessionRes) =>
            validateUserSuccess({ validateSessionRes })
          ),
          catchError((error) => of(validateUserFailed({ error })))
        )
      )
    );
  },
  { functional: true }
);
